import { createStore } from 'vuex'

export default createStore({
  state: {
    currentLanguage: 'th',
  },
  mutations: {
    setLanguage(data) {
      console.log('data', data);
    }
  },
  actions: {
    setLanguage({ commit }, payload) {
      this.state.currentLanguage = payload
    }
  },
  modules: {
  }
})
