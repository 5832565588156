
import { defineComponent } from 'vue'
import { LeftOutlined, RightOutlined } from '@ant-design/icons-vue'

export default defineComponent({
  props: {
    slideToShow: {
      type: Number,
      required: false,
      default: 4
    },
    totalItem: {
      type: Number
    }
  },
  components: {
    LeftOutlined,
    RightOutlined
  },
  setup () {
    const onSlideChange = (current: number) => {
      // console.log(current)
    }

    return {
      // ...toRefs(props),
      onSlideChange
    }
  }
})
