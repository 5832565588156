
import { defineComponent, watch, reactive, toRefs, ref, onMounted } from "vue";
import { RouteLocationRaw, useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { DownOutlined, GlobalOutlined } from "@ant-design/icons-vue";
import { useCookie } from "@vue-composable/cookie";
import { MenuInfo } from "ant-design-vue/lib/menu/src/interface";
import {useStore} from "vuex";

// import SatIcon from '@/components/SatIcon.vue'
interface OwnState {
      ssoRoute:string

  routes: RouteLocationRaw[];
  currentSelectedMenu: string[];
}
export default defineComponent({
  components: {
    DownOutlined,
    GlobalOutlined,
    // SatIcon
  },
  setup(props) {
    const { cookie, setCookie } = useCookie(process.env.VUE_APP_COOKIE_KEY);
    const store = useStore();

    const { t, availableLocales, locale } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    const anotherLocale = ref(availableLocales.find((l) => l !== locale.value));
    const route = useRoute();
    const router = useRouter();
    const state = reactive<OwnState>({
              ssoRoute:'',

      routes: router.options.routes.filter((ele) => ele.meta?.visible),
      currentSelectedMenu: [String(route.name).toLowerCase()],
    });

    // onMounted(() => {
    // const cookies = localStorage?.getItem('bdadmin') as string
    //   setCookie(cookies)
    // });

    const handleChangeLocale = (v: string) => {
      locale.value = v;
      anotherLocale.value = availableLocales.find((l) => l !== locale.value);
      localStorage.setItem('language',locale.value)
      store.dispatch('setLanguage',locale.value)
    };
    watch(
      () => route.name,
      (newName) => {
        if (newName) {
                      localStorage.setItem('language','th')

          state.currentSelectedMenu = [String(newName).toLowerCase()];
          if (!cookie.value && newName !== "sso") {
            router.replace({ name: "Disallowed" });
          }
        }
      },
      { immediate: true }
    );

     watch(() => cookie.value, cookieValue => {
      if (cookieValue) {
        state.ssoRoute = cookieValue
        // router.replace({ name: 'Disallowed' })
      }
    }, { immediate: true })
    return {
      t,
      availableLocales,
      anotherLocale,
      locale,
      cookie,
      handleChangeLocale,
      ...toRefs(state),
    };
  },
});
