import { ref, Ref } from 'vue';
import { athleteService } from '@/_services/athlete.service';
import { useRequest } from 'vue-request';
import {
  BaseResponse,
  Athlete,
  IAthlete,
  AthleteParam,
  FootballPosition,
  AthleteQueryDto,
  QueryParams,
  AthleteTeamHistory
} from '@/_modules/types';

interface ReturnType {
  repositories: Ref<IAthlete[]>;
  loading: Ref<boolean>;
  getAthleteRepositories: (
    params?: AthleteParam,
    filter?: FootballPosition
  ) => Promise<IAthlete[] | null>;
  getAthleteOverview: (params: AthleteQueryDto) => Promise<IAthlete[] | null>;
  fetchingOverview: Ref<boolean>;
  getAthleteOverviewByTeam: (
    teamId: number,
    filter: FootballPosition,
    params: QueryParams
  ) => Promise<IAthlete[] | null>;
  fetchingOverviewByTeam: Ref<boolean>;
}
export default function useAthleteRepositories(): ReturnType {
  const repositories = ref<IAthlete[]>([]);
  const cb = (ele: Athlete) => {
    const {
      account_informations,
      team_club,
      global_config_positions: positions,
      team_national,
      global_config_physical_fitness,
      account_id
    } = ele;
    const {
      account_information_firstname_th,
      account_information_lastname_th,
      account_information_firstname_en,
      account_information_lastname_en,
      account_media_preview_url
    } = account_informations;
    const teamsId = [
      ...(team_club?.team_id ? [team_club?.team_id] : []),
      ...(team_national?.team_id ? [team_national?.team_id] : [])
    ] as number[];
    return {
      id: account_id,
      name: {
        en: account_information_firstname_en
          ? `${account_information_firstname_en} ${account_information_lastname_en}`
          : '',
        th: `${account_information_firstname_th} ${account_information_lastname_th}`
      },
      age: account_informations.account_information_age ?? '-',
      nationallity: {
        th: account_informations.account_information_nationality ?? '-',
        en: account_informations.account_information_nationality_en ?? '-',
      },
      team: {
        club: { ...team_club },
        national: { ...team_national }
      },
      positions: positions?.map(pos => {
        const tmp: { [key: string]: any } = {};
        tmp[pos.global_config_position_abbreviation] =
        {
          en: pos.global_config_position_name_en,
          th: pos.global_config_position_name_th
        }

        return tmp;
      }),
      avatar: account_media_preview_url ?? '',
      fitness: {
        percentage:
          global_config_physical_fitness?.global_config_physical_fitness_percentage ??
          -1,
        suggestion: {
          en: global_config_physical_fitness?.global_config_physical_fitness_name_en ??
            '-',
          th: global_config_physical_fitness?.global_config_physical_fitness_name_th ??
          '-'
        }

      },
      teamsId
    } as IAthlete;
  };
  const formatAthleteResult = (result: BaseResponse<Athlete[]>) => {
    const { data } = result;
    const tmp = Array.isArray(data)
      ? data
      : (((data as unknown) as any).data as Athlete[]);
    return tmp?.map(cb);
  };

  const { run: getAthleteRepositories, loading } = useRequest(
    athleteService.list,
    {
      manual: true,
      formatResult: formatAthleteResult,
      onSuccess: formattedData => {
        repositories.value = formattedData;
      }
    }
  );

  const { run: getAthleteOverview, loading: fetchingOverview } = useRequest(
    athleteService.overview,
    {
      manual: true,
      // use any cause API return multiple JSON format T__T
      formatResult: ({ data }: any) => {
        console.log("getAthleteOverview :: ", data)
        return data?.account?.data?.map(cb) || [];
      }
    }
  );

  const {
    run: getAthleteOverviewByTeam,
    loading: fetchingOverviewByTeam
  } = useRequest(athleteService.overviewByTeam, {
    manual: true,
    formatResult: (result: any) => {
      const { account } = result?.data?.data ?? result?.data ?? {};
      return account.data.map(cb);
    }
  });

  return {
    repositories,
    loading,
    getAthleteRepositories,
    getAthleteOverview,
    fetchingOverview,
    getAthleteOverviewByTeam,
    fetchingOverviewByTeam
  };
}
