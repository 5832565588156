
import { IAthlete, IStaff } from "@/_modules/types";
import { defineComponent, PropType } from "vue";
import { Helpers } from "@/_modules/helpers";
import SatIcon from "@/components/SatIcon.vue";
import {useStore} from "vuex";
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: {
    SatIcon,
  },
  props: {
    type: {
      type: String,
      // validator (type) {
      //   return ['athlete', 'staff', 'injured', 'tournament', 'team', 'stadium'].includes(`${type}`)
      // }
    },
    data: Object as PropType<IAthlete | IStaff>,
    page: String,
  },
  setup(props) {
    const { data } = props;
        const store = useStore();
const { t,locale } = useI18n({
      useScope: "global",
    });
    const placeHolder =
      props.type === "staffs"
        ? require("@/assets/staff-placeholder.svg")
        : require("@/assets/athlete-placeholder.svg");
    return {
      t,
      placeHolder,
      Helpers,
      store
    };
  },
});
