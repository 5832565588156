
import {
    BaseResponse,
    Competition,
    ICompetition,
    Athlete,
    IAthlete,
    TeamDetail,
    AthleteParam,
    FootballPosition,
    AthleteQueryDto,
    QueryParams,
    CompetitionOverviewQuery,
    AtheteQueryOverviewDto,
    Staff, IStaff,
    TeamOverviewQuery,
    ITeam,
    IOverview,
    IOverviewTeam,
    IOverviewCompetition
} from '@/_modules/types';
import { useRequest } from 'vue-request';
import { format } from 'date-fns'
import { th } from 'date-fns/locale'
import addYears from "date-fns/addYears";
import { homeService } from '@/_services/home.service'
interface ReturnType {
    getOverviewAthlete: (params: AtheteQueryOverviewDto) => Promise<IOverview | null>;
    getOverviewStaff: (params: QueryParams) => Promise<any | null>
    getOverviewTeams: (params: TeamOverviewQuery) => Promise<any | null>
    getTotalOverviewTeam: (params: TeamOverviewQuery) => Promise<any | null>
    getOverviewCompetition: (params: CompetitionOverviewQuery) => Promise<any | null>;
    getOverviewMatchCompetition: (params: CompetitionOverviewQuery) => Promise<any | null>;
}

interface position {
    en: string
    th: string
}

export default function useOverviewRepositories(): ReturnType {
    const athleteMap = (ele: Athlete) => {
        const {
            account_informations,
            team_club,
            global_config_positions: positions,
            team_national,
            global_config_physical_fitness,
            account_id,
            account_injuries
        } = ele;
        const {
            account_information_firstname_th,
            account_information_lastname_th,
            account_information_firstname_en,
            account_information_lastname_en,
            account_media_preview_url
        } = account_informations;
        const teamsId = [
            ...(team_club?.team_id ? [team_club?.team_id] : []),
            ...(team_national?.team_id ? [team_national?.team_id] : [])
        ] as number[];

        return {
            id: account_id,
            name: {
                en: account_information_firstname_en
                    ? `${account_information_firstname_en} ${account_information_lastname_en}`
                    : '',
                th: `${account_information_firstname_th} ${account_information_lastname_th}`
            },
            age: account_informations.account_information_age ?? '-',
            nationallity: {
                th: account_informations.account_information_nationality ?? '-',
                en: account_informations.account_information_nationality_en ?? '-',
            },
            team: {
                club: { ...team_club },
                national: { ...team_national }
            },
            positions: positions?.map(pos => {
                const tmp: { [key: string]: any } = {};
                tmp[pos.global_config_position_abbreviation] = {
                    en: pos.global_config_position_name_en,
                    th: pos.global_config_position_name_th
                }
                // pos.global_config_position_name_th;
                return tmp;
            }),
            avatar: account_media_preview_url ?? '',
            fitness: {
                percentage:
                    global_config_physical_fitness?.global_config_physical_fitness_percentage ??
                    -1,
                suggestion: {
                    en: global_config_physical_fitness?.global_config_physical_fitness_name_en ??
                        '-',
                    th: global_config_physical_fitness?.global_config_physical_fitness_name_th ??
                        '-'
                }

            },
            teamsId,
            injuries: {
                level: account_injuries[0]?.account_injury_level,
                // name: account_injuries[0]?.account_injury_name_th,
                name: {
                    en: account_injuries[0]?.account_injury_name_en,
                    th: account_injuries[0]?.account_injury_name_th
                },
                number: account_injuries[0]?.account_injury_recovery_time,
                type: account_injuries[0]?.account_injury_recovery_time_unit
            } || {}
        } as IAthlete;
    };

    const { run: getOverviewAthlete } = useRequest(
        homeService.getLastestAthlete,
        {
            manual: true,
            formatResult: (result: any) => {
                const account = result?.data?.data ?? result?.data ?? {};
                if (account.account.last_updated) {
                    const formatDateTH = format(addYears(new Date(account?.account.last_updated), 543), 'dd MMMM yyyy', { locale: th })
                    const formatDateEN = format(new Date(account.account?.last_updated), 'dd MMMM yyyy')

                    return {
                        data: account.account.data.map(athleteMap),
                        lastUpdated: {
                            th: formatDateTH,
                            en: formatDateEN
                        }
                    }
                } else {
                    return {
                        data: account.account.data.map(athleteMap),
                        lastUpdated: {
                            th: "",
                            en: ""
                        }
                    }
                }

            }
        }
    );

    const staffMap = (ele: Staff) => {
        if (!ele) {
            return {} as IStaff
        }
        const { account_informations, account_id, global_config_positions: positions, team_club, team_national, midical_conditions, social_contacts, account_education, global_config_departments: departments } = ele
        const {
            account_information_firstname_th: firstNameTh,
            account_information_lastname_th: lastNameTh,
            account_information_firstname_en: firstName,
            account_information_lastname_en: lastName,
            account_information_age: age,
            account_information_nationality: nationallity,
            account_information_nationality_en: nationallityEn,
            account_media_preview_url: avatar,
            account_information_blood_type: blood,
            account_information_biography_th: bioTh,
            account_information_biography_en: bioEn
        } = account_informations ?? {}
        const teamsId = [
            ...team_club?.team_id ? [team_club?.team_id] : [],
            ...team_national?.team_id ? [team_national?.team_id] : []
        ] as number[]

        const {
            account_social_contact_email: email,
            account_social_contact_facebook: facebook,
            account_social_contact_fax: fax,
            account_social_contact_instagram: instagram,
            account_social_contact_line: line,
            account_social_contact_twitter: twitter,
            account_social_contact_website: website,
            account_social_contact_youtube: youtube
        } = social_contacts ?? {}

        const medical = midical_conditions?.map(medi => {
            const tmp: { [key: string]: { th: string, en: string } } = {}
            tmp[medi.account_medical_condition_type] = {
                th: medi?.account_medical_condition_name_th ?? '-',
                en: medi?.account_medical_condition_name_en ?? '-',
            }
            return tmp
        }) as any



        return {
            id: account_id,
            name: {
                en: `${firstName ?? ''} ${lastName ?? ''}`,
                th: `${firstNameTh ?? ''} ${lastNameTh ?? ''}`,
            },
            age: age ?? '-',
            nationallity: {
                th: nationallity ?? '-',
                en: nationallityEn ?? '-'
            },
            avatar,
            positions: [...positions?.map(p => {
                const { global_config_position_abbreviation: ab, global_config_position_main_position: main, global_config_position_name_en, global_config_position_name_th } = p
                const tmp = {} as { [key: string]: { th: string, en: string } }
                tmp[ab ?? global_config_position_name_en?.charAt(0) ?? '_'] = {
                    th: global_config_position_name_th,
                    en: global_config_position_name_en
                }
                return tmp
            }) ?? []],
            department: departments,
            team: {
                club: team_club?.setting_team_information_name_th ?? '-',
                national: team_national?.setting_team_information_name_en ?? '-',
                logo: {
                    club: team_club?.setting_team_media_profile_url,
                    national: team_national?.setting_team_media_profile_url
                }
            },
            teamsId,
            congential: midical_conditions?.find((p: any) =>
                p?.account_medical_condition_type === "CONGENTIAL_DISEASE"
            ),
            food: midical_conditions?.find((p: any) =>
                p?.account_medical_condition_type === "FOOD_ALLERGY"
            ),
            drug: midical_conditions?.find((p: any) =>
                p?.account_medical_condition_type === "DRUG_ALLERGY"
            ),
            blood,
            social_contacts: {
                email: email ?? '-', facebook: facebook ?? '-', fax: fax ?? '-', instagram: instagram ?? '-', line: line ?? '-', twitter: twitter ?? '-', website: website ?? '-', youtube: youtube ?? '-',
            },
            account_education,
            bioTh,
            bioEn
        } as any
    }

    const { run: getOverviewStaff } = useRequest(
        homeService.getLastestStaff,
        {
            manual: true,
            formatResult: (result: any) => {
                const staff = result?.data?.data ?? result?.data ?? {};
                const formatDateTH = format(addYears(new Date(staff.account.last_updated), 543), 'dd MMMM yyyy', { locale: th })
                const formatDateEN = format(new Date(staff.account.last_updated), 'dd MMMM yyyy')
                return {
                    data: staff.account.data.map(staffMap),
                    lastUpdated: {
                        th: formatDateTH,
                        en: formatDateEN
                    }
                }

            }
        }
    )

    const teamMap = (ele: TeamDetail) => {
        const { id, setting_team_informations, global_config_local_types, setting_team_region_country, setting_team_social_contacts, setting_team_regions_name } = ele
        const {
            setting_team_media_profile_url: logo,
            setting_team_information_name_en: en,
            setting_team_information_name_th: th,
            setting_team_information_abbreviation: abbreviation,
            setting_team_information_established: established,
            setting_team_information_nickname: nickname,
            setting_team_information_history_th: history
        } = setting_team_informations
        const { global_config_local_type_name_th: nationCategory, global_config_local_type_name_en: nationCategoryEN } = global_config_local_types
        const dataRespon: ITeam = {
            id,
            name: {
                en: en ?? '-',
                th: th ?? '-',
                abbreviation,
                established,
                nickname: nickname ?? '-'
            },
            country_th: setting_team_region_country?.country_name_th ?? '-',
            country_en: setting_team_region_country?.country_name_en ?? '-',
            logo,
            nationCategory,
            nationCategoryEN,
            history,
            contact: {
                setting_team_social_contact_email: setting_team_social_contacts?.setting_team_social_contact_email ?? '-',
                setting_team_social_contact_facebook: setting_team_social_contacts?.setting_team_social_contact_facebook ?? '-',
                setting_team_social_contact_fax: setting_team_social_contacts?.setting_team_social_contact_fax ?? '-',
                setting_team_social_contact_line: setting_team_social_contacts?.setting_team_social_contact_line ?? '-',
                setting_team_social_contact_phone: setting_team_social_contacts?.setting_team_social_contact_phone ?? '-',
                setting_team_social_contact_instagram: setting_team_social_contacts?.setting_team_social_contact_instagram ?? '-',
                setting_team_social_contact_twitter: setting_team_social_contacts?.setting_team_social_contact_twitter ?? '-',
                setting_team_social_contact_website: setting_team_social_contacts?.setting_team_social_contact_website ?? '-',
                setting_team_social_contact_youtube: setting_team_social_contacts?.setting_team_social_contact_youtube ?? '-',
            },
            address: {
                address: setting_team_region_country?.setting_stadium_region_address_detail ?? '-',
                subdistrict: setting_team_region_country?.sub_district_name_th ?? '-',
                district: setting_team_region_country?.district_name_en ?? '-',
                province: setting_team_region_country?.province_name_th ?? '-',
                country: setting_team_region_country?.country_name_th ?? '-',
                zipcode: setting_team_region_country?.setting_team_region_zipcode ?? '-',
            },
        }
        return dataRespon
    }

    const { run: getOverviewTeams } = useRequest(
        homeService.getLastestTeam,
        {
            manual: true,
            formatResult: (result: any) => {
                const { teams } = result.data ?? result
                if (!teams?.data) return []
                const formatDateTH = format(addYears(new Date(teams.last_updated), 543), 'dd MMMM yyyy', { locale: th })
                const formatDateEN = format(new Date(teams.last_updated), 'dd MMMM yyyy')

                return {
                    data: teams.data?.map(teamMap),
                    lastUpdated: {
                        th: formatDateTH,
                        en: formatDateEN
                    }
                }
            }
        }
    )

    const { run: getTotalOverviewTeam } = useRequest(
        homeService.getLastestTeam,
        {
            manual: true,
            formatResult: (result: any) => {
                const { teams } = result.data ?? result
                return teams || 0
            }
        }
    )

    const competitionMap = (ele: Competition) => {
        if (!ele) return {} as ICompetition
        const {
            id,
            setting_competition_informations: info,
            setting_competition_media_profile_icon: logo,
            global_config_standard_types,
            setting_competition_level,
            setting_competition_social_contacts: contact,
            count_sub_competition,
        } = ele
        const dataRespone: any = {
            id: id ?? 0,
            name: {
                en: info?.setting_competition_information_name_en ?? '-',
                th: info?.setting_competition_information_name_th ?? '-'
            },
            logo: logo ?? info.setting_competition_media_profile_url,
            host: info?.setting_competition_information_organizer_th ?? '-',
            info: {
                code: info?.setting_competition_information_code ?? '-',
                level: setting_competition_level ?? global_config_standard_types?.global_config_standard_type_name_th ?? '-',
                type: setting_competition_level ?? '-',
                teamType: global_config_standard_types?.global_config_standard_type_name_th ?? '-',
                totalSubCompetition: count_sub_competition ?? 0
            },
            history: info?.setting_competition_information_history_th ?? '-',
            contact: {
                email: contact?.setting_competition_social_contact_email || '-',
                phone: contact?.setting_competition_social_contact_phone || '-',
                instagram: contact?.setting_competition_social_contact_instagram || '-',
                line: contact?.setting_competition_social_contact_line || '-',
                facebook: contact?.setting_competition_social_contact_facebook || '-',
                twitter: contact?.setting_competition_social_contact_twitter || '-',
                youtube: contact?.setting_competition_social_contact_youtube || '-',
                website: contact?.setting_competition_social_contact_website || '-'
            },
        }
        return dataRespone
    }

    const { run: getOverviewCompetition } = useRequest(
        homeService.getLatestMatch,
        {
            manual: true,
            formatResult: (result: any) => {
                const { competitions } = result.data ?? result
                const formatDateTH = format(addYears(new Date(competitions.last_updated), 543), 'dd MMMM yyyy', { locale: th })
                const formatDateEN = format(new Date(competitions.last_updated), 'dd MMMM yyyy')

                if (!competitions?.data) return []
                return {
                    data: competitions.data?.map(competitionMap),
                    lastUpdated: {
                        th: formatDateTH,
                        en: formatDateEN
                    }
                }
            }
        }
    )

    const LastestMatch = (lasted: any, matchlist: any) => {
        if (lasted) {
            const {
                competition_sections,
                versus_match_team_detail_one_id: home,
                versus_match_team_detail_two_id: away,
                versus_match_date,
                versus_match_start_at,
                versus_match_end_at,
                setting_sub_competition
            } = lasted
            // console.log('lasted', competition_sections);

        }

        return "test"
    }

    const { run: getOverviewMatchCompetition } = useRequest(
        homeService.getLatestMatch,
        {
            manual: true,
            formatResult: (result: any) => {
                const { latest_versus_match, list_versus_match } = result.data ?? result
                return result.data ?? result
                // return LastestMatch(latest_versus_match, list_versus_match)
            }
        }
    )
    return {
        getOverviewAthlete,
        getOverviewStaff,
        getOverviewTeams,
        getTotalOverviewTeam,
        getOverviewCompetition,
        getOverviewMatchCompetition
    }
}