
import { ITeam } from "@/_modules/types";
import { defineComponent, PropType } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

export default defineComponent({
  props: {
    data: Object as PropType<ITeam>,
  },
  setup(props) {
    const store = useStore();
    const { t } = useI18n({
      useScope: "global",
    });
    const { data } = props;
    return {
      t,
      data,
      store
    };
  },
});
